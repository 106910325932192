// 省市级联数据
export default [
	{
		value: 'Beijing',
		label: '北京',
		children: [
			{
				value: 'dongcheng',
				label: '东城区',
			},
			{
				value: 'xicheng',
				label: '西城区',
			},
			{
				value: 'chongwen',
				label: '崇文区',
			},
			{
				value: 'xuanwu',
				label: '宣武区',
			},
			{
				value: 'chaoyang',
				label: '朝阳区',
			},
			{
				value: 'haiding',
				label: '海淀区',
			},
			{
				value: 'fengtai',
				label: '丰台区',
			},
			{
				value: 'shijingshan',
				label: '石景山区',
			},
			{
				value: 'fangshan',
				label: '房山区',
			},
			{
				value: 'tongzhou',
				label: '通州区',
			},
			{
				value: 'shunyi',
				label: '顺义区',
			},
			{
				value: 'changping',
				label: '昌平区',
			},
			{
				value: 'daxing',
				label: '大兴区',
			},
			{
				value: 'huairou',
				label: '怀柔区',
			},
			{
				value: 'pinggu',
				label: '平谷区',
			},
			{
				value: 'mentougou',
				label: '门头沟区',
			},
			{
				value: 'miyun',
				label: '密云县',
			},
			{
				value: 'yanqing',
				label: '延庆县',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'tianjing',
		label: '天津',
		children: [
			{
				value: 'heping',
				label: '和平区',
			},
			{
				value: 'hegong',
				label: '河东区',
			},
			{
				value: 'hexi',
				label: '河西区',
			},
			{
				value: 'nankai',
				label: '南开区',
			},
			{
				value: 'hebei',
				label: '河北区',
			},
			{
				value: 'hongqiao',
				label: '红桥区',
			},
			{
				value: 'tanggu',
				label: '塘沽区',
			},
			{
				value: 'hangu',
				label: '汉沽区',
			},
			{
				value: 'dagang',
				label: '大港区',
			},
			{
				value: 'dongli',
				label: '东丽区',
			},
			{
				value: 'xiqing',
				label: '西青区',
			},
			{
				value: 'beichen',
				label: '北辰区',
			},
			{
				value: 'jinnan',
				label: '津南区',
			},
			{
				value: 'wuqing',
				label: '武清区',
			},
			{
				value: 'baodi',
				label: '宝坻区',
			},
			{
				value: 'jinghai',
				label: '静海县',
			},
			{
				value: 'ninghe',
				label: '宁河县',
			},
			{
				value: 'jixian',
				label: '蓟县',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'hebei',
		label: '河北',
		children: [
			{
				value: 'shijiazhuang',
				label: '石家庄',
			},
			{
				value: 'tangshan',
				label: '唐山',
			},
			{
				value: 'qinhuangdao',
				label: '秦皇岛',
			},
			{
				value: 'hanchan',
				label: '邯郸',
			},
			{
				value: 'xingtai',
				label: '邢台',
			},
			{
				value: 'baoding',
				label: '保定',
			},
			{
				value: 'zhangjiakou',
				label: '张家口',
			},
			{
				value: 'chengde',
				label: '承德',
			},
			{
				value: 'cangzhou',
				label: '沧州',
			},
			{
				value: 'langfang',
				label: '廊坊',
			},
			{
				value: 'hengshui',
				label: '衡水',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'shanxi',
		label: '山西',
		children: [
			{
				value: 'taiyuan',
				label: '太原',
			},
			{
				value: 'datong',
				label: '大同',
			},
			{
				value: 'yangquan',
				label: '阳泉',
			},
			{
				value: 'changzhi',
				label: '长治',
			},
			{
				value: 'jincheng',
				label: '晋城',
			},
			{
				value: '',
				label: '朔州',
			},
			{
				value: '',
				label: '晋中',
			},
			{
				value: '',
				label: '运城',
			},
			{
				value: 'xīnzhou',
				label: '忻州',
			},
			{
				value: 'linfen',
				label: '临汾',
			},
			{
				value: 'lvliang',
				label: '吕梁',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'neimenggu',
		label: '内蒙古',
		children: [
			{
				value: 'huhehaote',
				label: '呼和浩特',
			},
			{
				value: 'baotou',
				label: '包头',
			},
			{
				value: 'wuhai',
				label: '乌海',
			},
			{
				value: 'chifeng',
				label: '赤峰',
			},
			{
				value: 'tongliao',
				label: '通辽',
			},
			{
				value: 'eerduosi',
				label: '鄂尔多斯',
			},
			{
				value: 'hulunbeier',
				label: '呼伦贝尔',
			},
			{
				value: 'bayanzhuoer',
				label: '巴彦淖尔',
			},
			{
				value: 'wulanchabu',
				label: '乌兰察布',
			},
			{
				value: 'xilinguole',
				label: '锡林郭勒盟',
			},
			{
				value: 'xingan',
				label: '兴安盟',
			},
			{
				value: 'alashan',
				label: '阿拉善盟',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'liaoning',
		label: '辽宁',
		children: [
			{
				value: 'shenyang',
				label: '沈阳',
			},
			{
				value: 'dalian',
				label: '大连',
			},
			{
				value: 'anshan',
				label: '鞍山',
			},
			{
				value: 'fushun',
				label: '抚顺',
			},
			{
				value: 'benxi',
				label: '本溪',
			},
			{
				value: 'dandong',
				label: '丹东',
			},
			{
				value: 'jinzhou',
				label: '锦州',
			},
			{
				value: 'yingkou',
				label: '营口',
			},
			{
				value: 'fuxin',
				label: '阜新',
			},
			{
				value: 'liaoyang',
				label: '辽阳',
			},
			{
				value: 'panjing',
				label: '盘锦',
			},
			{
				value: 'tieling',
				label: '铁岭',
			},
			{
				value: 'chaoyang',
				label: '朝阳',
			},
			{
				value: 'fuludao',
				label: '葫芦岛',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'jilin',
		label: '吉林',
		children: [
			{
				value: 'jilin',
				label: '吉林',
			},
			{
				value: 'siping',
				label: '四平',
			},
			{
				value: 'liaoyuan',
				label: '辽源',
			},
			{
				value: 'tonghua',
				label: '通化',
			},
			{
				value: 'baishan',
				label: '白山',
			},
			{
				value: 'songyuan',
				label: '松原',
			},
			{
				value: 'baicheng',
				label: '白城',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'heilongjiang',
		label: '黑龙江',
		children: [
			{
				value: 'haerbing',
				label: '哈尔滨',
			},
			{
				value: 'qiqihaer',
				label: '齐齐哈尔',
			},
			{
				value: 'hegang',
				label: '鹤岗',
			},
			{
				value: 'shuangyashan',
				label: '双鸭山',
			},
			{
				value: 'jixi',
				label: '鸡西',
			},
			{
				value: 'daqing',
				label: '大庆',
			},
			{
				value: 'yichun',
				label: '伊春',
			},
			{
				value: 'mudanjiang',
				label: '牡丹江',
			},
			{
				value: 'jiamusi',
				label: '佳木斯',
			},
			{
				value: 'xitai',
				label: '七台河',
			},
			{
				value: 'heihe',
				label: '黑河',
			},
			{
				value: 'suihua',
				label: '绥化',
			},
			{
				value: 'daxinganling',
				label: '大兴安岭地区',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'shanghai',
		label: '上海',
		children: [
			{
				value: 'huangpu',
				label: '黄浦区',
			},
			{
				value: 'lvwan',
				label: '卢湾区',
			},
			{
				value: 'xuhui',
				label: '徐汇区',
			},
			{
				value: 'changning',
				label: '长宁区',
			},
			{
				value: 'jingan',
				label: '静安区',
			},
			{
				value: 'putuo',
				label: '普陀区',
			},
			{
				value: 'xiabei',
				label: '闸北区',
			},
			{
				value: 'hongkou',
				label: '虹口区',
			},
			{
				value: 'yangpu',
				label: '杨浦区',
			},
			{
				value: 'baoshan',
				label: '宝山区',
			},
			{
				value: 'minxing',
				label: '闵行区',
			},
			{
				value: 'jiading',
				label: '嘉定区',
			},
			{
				value: 'songjiang',
				label: '松江区',
			},
			{
				value: 'jinshan',
				label: '金山区',
			},
			{
				value: 'qingpu',
				label: '青浦区',
			},
			{
				value: 'nanhui',
				label: '南汇区',
			},
			{
				value: 'fongxian',
				label: '奉贤区',
			},
			{
				value: 'pudongxinqu',
				label: '浦东新区',
			},
			{
				value: 'chongming',
				label: '崇明县',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'jiangsu',
		label: '江苏',
		children: [
			{
				value: 'nanjing',
				label: '南京',
			},
			{
				value: 'suzhou',
				label: '苏州',
			},
			{
				value: 'wuxi',
				label: '无锡',
			},
			{
				value: 'changzhou',
				label: '常州',
			},
			{
				value: 'zhenjiang',
				label: '镇江',
			},
			{
				value: 'nantong',
				label: '南通',
			},
			{
				value: 'taizhou',
				label: '泰州',
			},
			{
				value: 'yangzhou',
				label: '扬州',
			},
			{
				value: 'yancheng',
				label: '盐城',
			},
			{
				value: 'lianyungang',
				label: '连云港',
			},
			{
				value: 'xuzhou',
				label: '徐州',
			},
			{
				value: 'huanan',
				label: '淮安',
			},
			{
				value: 'suqian',
				label: '宿迁',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'zhejiang',
		label: '浙江',
		children: [
			{
				value: 'hangzhou',
				label: '杭州',
			},
			{
				value: 'ningbo',
				label: '宁波',
			},
			{
				value: 'wenzhou',
				label: '温州',
			},
			{
				value: 'jiaxing',
				label: '嘉兴',
			},
			{
				value: 'huzhou',
				label: '湖州',
			},
			{
				value: 'shaoxing',
				label: '绍兴',
			},
			{
				value: 'jinhau',
				label: '金华',
			},
			{
				value: 'quzhou',
				label: '衢州',
			},
			{
				value: 'zhoushan',
				label: '舟山',
			},
			{
				value: 'taizhou',
				label: '台州',
			},
			{
				value: 'lishui',
				label: '丽水',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'anhui',
		label: '安徽',
		children: [
			{
				value: 'hefei',
				label: '合肥',
			},
			{
				value: 'wuhu',
				label: '芜湖',
			},
			{
				value: 'bangbu',
				label: '蚌埠',
			},
			{
				value: 'huannan',
				label: '淮南',
			},
			{
				value: 'maanshan',
				label: '马鞍山',
			},
			{
				value: 'huaibei',
				label: '淮北',
			},
			{
				value: 'tongling',
				label: '铜陵',
			},
			{
				value: 'anqing',
				label: '安庆',
			},
			{
				value: 'huangshan',
				label: '黄山',
			},
			{
				value: 'chuzhou',
				label: '滁州',
			},
			{
				value: 'fuyang',
				label: '阜阳',
			},
			{
				value: 'suzhou',
				label: '宿州',
			},
			{
				value: 'chaohu',
				label: '巢湖',
			},
			{
				value: 'liuan',
				label: '六安',
			},
			{
				value: 'haozhou',
				label: '亳州',
			},
			{
				value: 'chizhou',
				label: '池州',
			},
			{
				value: 'xuancheng',
				label: '宣城',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'fujian',
		label: '福建',
		children: [
			{
				value: 'fuzhou',
				label: '福州',
			},
			{
				value: 'xiamen',
				label: '厦门',
			},
			{
				value: 'putian',
				label: '莆田',
			},
			{
				value: 'shanming',
				label: '三明',
			},
			{
				value: 'quanzhou',
				label: '泉州',
			},
			{
				value: 'zhangzhou',
				label: '漳州',
			},
			{
				value: 'nanping',
				label: '南平',
			},
			{
				value: 'longyan',
				label: '龙岩',
			},
			{
				value: 'ningde',
				label: '宁德',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'jiangxi',
		label: '江西',
		children: [
			{
				value: 'nanchang',
				label: '南昌',
			},
			{
				value: 'jingdezhen',
				label: '景德镇',
			},
			{
				value: 'pingxiang',
				label: '萍乡',
			},
			{
				value: 'jiujiang',
				label: '九江',
			},
			{
				value: 'xinyu',
				label: '新余',
			},
			{
				value: 'yintan',
				label: '鹰潭',
			},
			{
				value: 'ganzhou',
				label: '赣州',
			},
			{
				value: 'jian',
				label: '吉安',
			},
			{
				value: 'yichun',
				label: '宜春',
			},
			{
				value: 'fuzhou',
				label: '抚州',
			},
			{
				value: 'shangrao',
				label: '上饶',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'shandong',
		label: '山东',
		children: [
			{
				value: 'jinan',
				label: '济南',
			},
			{
				value: 'qingdao',
				label: '青岛',
			},
			{
				value: 'zibo',
				label: '淄博',
			},
			{
				value: 'zaozhuan',
				label: '枣庄',
			},
			{
				value: 'dongying',
				label: '东营',
			},
			{
				value: 'yantai',
				label: '烟台',
			},
			{
				value: 'weifang',
				label: '潍坊',
			},
			{
				value: 'jining',
				label: '济宁',
			},
			{
				value: 'taian',
				label: '泰安',
			},
			{
				value: 'weihai',
				label: '威海',
			},
			{
				value: 'rizhao',
				label: '日照',
			},
			{
				value: 'linqin',
				label: '临沂',
			},
			{
				value: 'dezhou',
				label: '德州',
			},
			{
				value: 'laiwu',
				label: '莱芜',
			},
			{
				value: 'liaocheng',
				label: '聊城',
			},
			{
				value: 'bingzhou',
				label: '滨州',
			},
			{
				value: 'heze',
				label: '菏泽',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'henan',
		label: '河南',
		children: [
			{
				value: 'zhengzhou',
				label: '郑州',
			},
			{
				value: 'kaifeng',
				label: '开封',
			},
			{
				value: 'luoyang',
				label: '洛阳',
			},
			{
				value: 'pingdingshan',
				label: '平顶山',
			},
			{
				value: 'anyang',
				label: '安阳',
			},
			{
				value: 'hebi',
				label: '鹤壁',
			},
			{
				value: 'xinxiang',
				label: '新乡',
			},
			{
				value: 'jiaozuo',
				label: '焦作',
			},
			{
				value: 'puyang',
				label: '濮阳',
			},
			{
				value: 'xuchang',
				label: '许昌',
			},
			{
				value: 'luohe',
				label: '漯河',
			},
			{
				value: 'sanmenxia',
				label: '三门峡',
			},
			{
				value: 'nanyang',
				label: '南阳',
			},
			{
				value: 'shangqiu',
				label: '商丘',
			},
			{
				value: 'xinyang',
				label: '信阳',
			},
			{
				value: 'zhoukou',
				label: '周口',
			},
			{
				value: 'zhumadian',
				label: '驻马店',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'hubei',
		label: '湖北',
		children: [
			{
				value: 'wuhan',
				label: '武汉',
			},
			{
				value: 'huangshi',
				label: '黄石',
			},
			{
				value: 'shiyan',
				label: '十堰',
			},
			{
				value: 'jingzhou',
				label: '荆州',
			},
			{
				value: 'yichang',
				label: '宜昌',
			},
			{
				value: 'xiangfan',
				label: '襄樊',
			},
			{
				value: 'ezhou',
				label: '鄂州',
			},
			{
				value: 'jingmen',
				label: '荆门',
			},
			{
				value: 'xiaogan',
				label: '孝感',
			},
			{
				value: 'huangang',
				label: '黄冈',
			},
			{
				value: 'xianning',
				label: '咸宁',
			},
			{
				value: 'suizhou',
				label: '随州',
			},
			{
				value: 'ensi',
				label: '恩施土家族苗族自治州',
			},
			{
				value: 'xiantao',
				label: '仙桃',
			},
			{
				value: 'tianmen',
				label: '天门',
			},
			{
				value: 'qianjiang',
				label: '潜江',
			},
			{
				value: 'shenlongjia',
				label: '神农架林区',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'hunan',
		label: '湖南',
		children: [
			{
				value: 'changsha',
				label: '长沙',
			},
			{
				value: 'shuzhou',
				label: '株洲',
			},
			{
				value: 'xiangtan',
				label: '湘潭',
			},
			{
				value: 'hengyang',
				label: '衡阳',
			},
			{
				value: 'shaoyang',
				label: '邵阳',
			},
			{
				value: 'yueyang',
				label: '岳阳',
			},
			{
				value: 'changde',
				label: '常德',
			},
			{
				value: 'zhangjiajie',
				label: '张家界',
			},
			{
				value: 'yiyang',
				label: '益阳',
			},
			{
				value: 'chenzhou',
				label: '郴州',
			},
			{
				value: 'yongzhou',
				label: '永州',
			},
			{
				value: 'huaihua',
				label: '怀化',
			},
			{
				value: 'loudi',
				label: '娄底',
			},
			{
				value: 'xiangxi',
				label: '湘西土家族苗族自治州',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'guangdong',
		label: '广东',
		children: [
			{
				value: 'guangzhou',
				label: '广州',
			},
			{
				value: 'shenzhen',
				label: '深圳',
			},
			{
				value: 'zhuhai',
				label: '珠海',
			},
			{
				value: 'shantou',
				label: '汕头',
			},
			{
				value: 'shaoguan',
				label: '韶关',
			},
			{
				value: 'foshan',
				label: '佛山',
			},
			{
				value: 'jiangmen',
				label: '江门',
			},
			{
				value: 'zhanjiang',
				label: '湛江',
			},
			{
				value: 'maoming',
				label: '茂名',
			},
			{
				value: 'zhaoqing',
				label: '肇庆',
			},
			{
				value: 'huizhou',
				label: '惠州',
			},
			{
				value: 'meizhou',
				label: '梅州',
			},
			{
				value: 'shanwei',
				label: '汕尾',
			},
			{
				value: 'heyuan',
				label: '河源',
			},
			{
				value: 'yangjiang',
				label: '阳江',
			},
			{
				value: 'qingyuan',
				label: '清远',
			},
			{
				value: 'dongguan',
				label: '东莞',
			},
			{
				value: 'zhongshan',
				label: '中山',
			},
			{
				value: 'chaozhou',
				label: '潮州',
			},
			{
				value: 'jieyang',
				label: '揭阳',
			},
			{
				value: 'yunfu',
				label: '云浮',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'guangxi',
		label: '广西',
		children: [
			{
				value: 'nanning',
				label: '南宁',
			},
			{
				value: 'liuzhou',
				label: '柳州',
			},
			{
				value: 'guilin',
				label: '桂林',
			},
			{
				value: 'wuzhou',
				label: '梧州',
			},
			{
				value: 'beihai',
				label: '北海',
			},
			{
				value: 'fangchenggang',
				label: '防城港',
			},
			{
				value: 'qinzhou',
				label: '钦州',
			},
			{
				value: 'guigang',
				label: '贵港',
			},
			{
				value: 'yulin',
				label: '玉林',
			},
			{
				value: 'baise',
				label: '百色',
			},
			{
				value: 'hezhou',
				label: '贺州',
			},
			{
				value: 'hechi',
				label: '河池',
			},
			{
				value: 'laibing',
				label: '来宾',
			},
			{
				value: 'chongzuo',
				label: '崇左',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'hainan',
		label: '海南',
		children: [
			{
				value: 'haikou',
				label: '海口',
			},
			{
				value: 'sanya',
				label: '三亚',
			},
			{
				value: 'wuzhishan',
				label: '五指山',
			},
			{
				value: 'qionghai',
				label: '琼海',
			},
			{
				value: 'danzhou',
				label: '儋州',
			},
			{
				value: 'wenchang',
				label: '文昌',
			},
			{
				value: 'wanning',
				label: '万宁',
			},
			{
				value: 'dongfang',
				label: '东方',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'chongqing',
		label: '重庆',
		children: [
			{
				value: 'yuzhong',
				label: '渝中区',
			},
			{
				value: 'dadukou',
				label: '大渡口区',
			},
			{
				value: 'jiangbei',
				label: '江北区',
			},
			{
				value: 'nanan',
				label: '南岸区',
			},
			{
				value: 'beipei',
				label: '北碚区',
			},
			{
				value: 'yubei',
				label: '渝北区',
			},
			{
				value: 'banan',
				label: '巴南区',
			},
			{
				value: 'changshou',
				label: '长寿区',
			},
			{
				value: 'shuangqiao',
				label: '双桥区',
			},
			{
				value: 'shapingbei',
				label: '沙坪坝区',
			},
			{
				value: 'wansheng',
				label: '万盛区',
			},
			{
				value: 'wanzhou',
				label: '万州区',
			},
			{
				value: 'peiling',
				label: '涪陵区',
			},
			{
				value: 'qinjiang',
				label: '黔江区',
			},
			{
				value: 'yongchuan',
				label: '永川区',
			},
			{
				value: 'hechuan',
				label: '合川区',
			},
			{
				value: 'jiangjin',
				label: '江津区',
			},
			{
				value: 'jiulongpo',
				label: '九龙坡区',
			},
			{
				value: 'nanchuan',
				label: '南川区',
			},
			{
				value: 'qijiang',
				label: '綦江县',
			},
			{
				value: 'tongnan',
				label: '潼南县',
			},
			{
				value: 'rongchang',
				label: '荣昌县',
			},
			{
				value: 'bishan',
				label: '璧山县',
			},
			{
				value: 'dazu',
				label: '大足县',
			},
			{
				value: 'tongliang',
				label: '铜梁县',
			},
			{
				value: 'liangping',
				label: '梁平县',
			},
			{
				value: 'kaitan',
				label: '开县',
			},
			{
				value: 'zhoutan',
				label: '忠县',
			},
			{
				value: 'chengkou',
				label: '城口县',
			},
			{
				value: 'dianjiang',
				label: '垫江县',
			},
			{
				value: 'wulong',
				label: '武隆县',
			},
			{
				value: 'fengdou',
				label: '丰都县',
			},
			{
				value: 'fongjie',
				label: '奉节县',
			},
			{
				value: 'yunyang',
				label: '云阳县',
			},
			{
				value: 'wuxi',
				label: '巫溪县',
			},
			{
				value: 'wushan',
				label: '巫山县',
			},
			{
				value: 'shizhu',
				label: '石柱土家族自治县',
			},
			{
				value: 'xiushan',
				label: '秀山土家族苗族自治县',
			},
			{
				value: 'youyang',
				label: '酉阳土家族苗族自治县',
			},
			{
				value: 'pengshui',
				label: '彭水苗族土家族自治县',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'sichuan',
		label: '四川',
		children: [
			{
				value: 'shengdou',
				label: '成都',
			},
			{
				value: 'zigong',
				label: '自贡',
			},
			{
				value: 'panzhihua',
				label: '攀枝花',
			},
			{
				value: 'luzhou',
				label: '泸州',
			},
			{
				value: 'deyang',
				label: '德阳',
			},
			{
				value: 'mianyang',
				label: '绵阳',
			},
			{
				value: 'guangyuan',
				label: '广元',
			},
			{
				value: 'suining',
				label: '遂宁',
			},
			{
				value: 'neijiang',
				label: '内江',
			},
			{
				value: 'leshan',
				label: '乐山',
			},
			{
				value: 'nanchong',
				label: '南充',
			},
			{
				value: 'meishan',
				label: '眉山',
			},
			{
				value: 'yibin',
				label: '宜宾',
			},
			{
				value: 'guangan',
				label: '广安',
			},
			{
				value: 'dazhou',
				label: '达州',
			},
			{
				value: 'yaan',
				label: '雅安',
			},
			{
				value: 'bazhong',
				label: '巴中',
			},
			{
				value: 'ziyang',
				label: '资阳',
			},
			{
				value: 'abeizangzu',
				label: '阿坝藏族羌族自治州',
			},
			{
				value: 'ganzizangzu',
				label: '甘孜藏族自治州',
			},
			{
				value: 'liangshannizu',
				label: '凉山彝族自治州',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'guizhou',
		label: '贵州',
		children: [
			{
				value: 'guiyang',
				label: '贵阳',
			},
			{
				value: 'liupanshui',
				label: '六盘水',
			},
			{
				value: 'zunyi',
				label: '遵义',
			},
			{
				value: 'anshun',
				label: '安顺',
			},
			{
				value: 'tongren',
				label: '铜仁地区',
			},
			{
				value: 'bijie',
				label: '毕节地区',
			},
			{
				value: 'qianxi',
				label: '黔西南布依族苗族自治州',
			},
			{
				value: 'qiandong',
				label: '黔东南苗族侗族自治州',
			},
			{
				value: 'qiannan',
				label: '黔南布依族苗族自治州',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'yunnan',
		label: '云南',
		children: [
			{
				value: 'hunming',
				label: '昆明',
			},
			{
				value: 'qujing',
				label: '曲靖',
			},
			{
				value: 'yuxi',
				label: '玉溪',
			},
			{
				value: 'baoshan',
				label: '保山',
			},
			{
				value: 'zhaotong',
				label: '昭通',
			},
			{
				value: 'lijiang',
				label: '丽江',
			},
			{
				value: 'puer',
				label: '普洱',
			},
			{
				value: 'lincang',
				label: '临沧',
			},
			{
				value: 'dehong',
				label: '德宏傣族景颇族自治州',
			},
			{
				value: 'nujiangli',
				label: '怒江傈僳族自治州',
			},
			{
				value: 'diqing',
				label: '迪庆藏族自治州',
			},
			{
				value: 'dali',
				label: '大理白族自治州',
			},
			{
				value: 'xiongchu',
				label: '楚雄彝族自治州',
			},
			{
				value: 'honghe',
				label: '红河哈尼族彝族自治州',
			},
			{
				value: 'wenshan',
				label: '文山壮族苗族自治州',
			},
			{
				value: 'xishuangbanna',
				label: '西双版纳傣族自治州',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'xizang',
		label: '西藏',
		children: [
			{
				value: 'lasa',
				label: '拉萨',
			},
			{
				value: 'naqu',
				label: '那曲地区',
			},
			{
				value: 'changdou',
				label: '昌都地区',
			},
			{
				value: 'linzhi',
				label: '林芝地区',
			},
			{
				value: 'shannan',
				label: '山南地区',
			},
			{
				value: 'eke',
				label: '日喀则地区',
			},
			{
				value: 'ali',
				label: '阿里地区',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'shanxi',
		label: '陕西',
		children: [
			{
				value: 'xian',
				label: '西安',
			},
			{
				value: 'tongchuan',
				label: '铜川',
			},
			{
				value: 'baoji',
				label: '宝鸡',
			},
			{
				value: 'xianyang',
				label: '咸阳',
			},
			{
				value: 'weinan',
				label: '渭南',
			},
			{
				value: 'yanan',
				label: '延安',
			},
			{
				value: 'hanzhong',
				label: '汉中',
			},
			{
				value: 'yulin',
				label: '榆林',
			},
			{
				value: 'ankang',
				label: '安康',
			},
			{
				value: 'shangluo',
				label: '商洛',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'gansu',
		label: '甘肃',
		children: [
			{
				value: 'lanzhou',
				label: '兰州',
			},
			{
				value: 'jiayuguan',
				label: '嘉峪关',
			},
			{
				value: 'jinchang',
				label: '金昌',
			},
			{
				value: 'baiying',
				label: '白银',
			},
			{
				value: 'tianshui',
				label: '天水',
			},
			{
				value: 'wuwei',
				label: '武威',
			},
			{
				value: 'jiuquan',
				label: '酒泉',
			},
			{
				value: 'zhangye',
				label: '张掖',
			},
			{
				value: 'qingyang',
				label: '庆阳',
			},
			{
				value: 'pingliang',
				label: '平凉',
			},
			{
				value: 'dingxi',
				label: '定西',
			},
			{
				value: 'longnan',
				label: '陇南',
			},
			{
				value: 'linxia',
				label: '临夏回族自治州',
			},
			{
				value: 'gannan',
				label: '甘南藏族自治州',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'qinghai',
		label: '青海',
		children: [
			{
				value: 'xining',
				label: '西宁',
			},
			{
				value: 'haidong',
				label: '海东地区',
			},
			{
				value: 'haibei',
				label: '海北藏族自治州',
			},
			{
				value: 'hainan',
				label: '海南藏族自治州',
			},
			{
				value: 'huangnan',
				label: '黄南藏族自治州',
			},
			{
				value: 'guoluo',
				label: '果洛藏族自治州',
			},
			{
				value: 'yushu',
				label: '玉树藏族自治州',
			},
			{
				value: 'haixi',
				label: '海西蒙古族藏族自治州',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'ningxia',
		label: '宁夏',
		children: [
			{
				value: 'yinchuan',
				label: '银川',
			},
			{
				value: 'sizuishan',
				label: '石嘴山',
			},
			{
				value: 'wuzhong',
				label: '吴忠',
			},
			{
				value: 'guyuan',
				label: '固原',
			},
			{
				value: 'zhongwei',
				label: '中卫',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'xinjiang',
		label: '新疆',
		children: [
			{
				value: 'wulumuqi',
				label: '乌鲁木齐',
			},
			{
				value: 'kelamayi',
				label: '克拉玛依',
			},
			{
				value: 'tufan',
				label: '吐鲁番地区',
			},
			{
				value: 'hami',
				label: '哈密地区',
			},
			{
				value: 'hetian',
				label: '和田地区',
			},
			{
				value: 'akesu',
				label: '阿克苏地区',
			},
			{
				value: 'keshi',
				label: '喀什地区',
			},
			{
				value: 'kezilesu',
				label: '克孜勒苏柯尔克孜自治州',
			},
			{
				value: 'bayinguoleng',
				label: '巴音郭楞蒙古自治州',
			},
			{
				value: 'changji',
				label: '昌吉回族自治州',
			},
			{
				value: 'boertala',
				label: '博尔塔拉蒙古自治州',
			},
			{
				value: 'shihezi',
				label: '石河子',
			},
			{
				value: 'alaer',
				label: '阿拉尔',
			},
			{
				value: 'tumushuke',
				label: '图木舒克',
			},
			{
				value: 'wujiaqu',
				label: '五家渠',
			},
			{
				value: 'yili',
				label: '伊犁哈萨克自治州',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'xianggan',
		label: '香港',
		children: [
			{
				value: 'zhongxi',
				label: '中西区',
			},
			{
				value: 'wanzai',
				label: '湾仔区',
			},
			{
				value: 'dongqu',
				label: '东区',
			},
			{
				value: 'nanqu',
				label: '南区',
			},
			{
				value: 'shenshuibu',
				label: '深水埗区',
			},
			{
				value: 'youjianwang',
				label: '油尖旺区',
			},
			{
				value: 'jiulongcheng',
				label: '九龙城区',
			},
			{
				value: 'huangdaxian',
				label: '黄大仙区',
			},
			{
				value: 'guantang',
				label: '观塘区',
			},
			{
				value: 'beiqu',
				label: '北区',
			},
			{
				value: 'dapu',
				label: '大埔区',
			},
			{
				value: 'shatian',
				label: '沙田区',
			},
			{
				value: 'xigong',
				label: '西贡区',
			},
			{
				value: 'yuanlang',
				label: '元朗区',
			},
			{
				value: 'tunmen',
				label: '屯门区',
			},
			{
				value: 'quanwan',
				label: '荃湾区',
			},
			{
				value: 'kuiqing',
				label: '葵青区',
			},
			{
				value: 'liniao',
				label: '离岛区',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'aomen',
		label: '澳门',
		children: [
			{
				value: 'huadima',
				label: '花地玛堂区',
			},
			{
				value: 'shenganduoni',
				label: '圣安多尼堂区',
			},
			{
				value: 'datang',
				label: '大堂区',
			},
			{
				value: 'wangdetang',
				label: '望德堂区',
			},
			{
				value: 'fengshuntang',
				label: '风顺堂区',
			},
			{
				value: 'jiamotang',
				label: '嘉模堂区',
			},
			{
				value: 'shengfanji',
				label: '圣方济各堂区',
			},
			{
				value: 'ludang',
				label: '路凼',
			},
			{
				value: '',
				label: '',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'taiwan',
		label: '台湾',
		children: [
			{
				value: 'taibei',
				label: '台北市',
			},
			{
				value: 'gaoxiong',
				label: '高雄市',
			},
			{
				value: 'taibeixian',
				label: '台北县',
			},
			{
				value: 'taoyuan',
				label: '桃园县',
			},
			{
				value: 'xinzhu',
				label: '新竹县',
			},
			{
				value: 'miaolixian',
				label: '苗栗县',
			},
			{
				value: 'taizhongxian',
				label: '台中县',
			},
			{
				value: 'zhanghuaxian',
				label: '彰化县',
			},
			{
				value: 'nantouxian',
				label: '南投县',
			},
			{
				value: 'yunlinxian',
				label: '云林县',
			},
			{
				value: 'jiayixian',
				label: '嘉义县',
			},
			{
				value: 'tainanxian',
				label: '台南县',
			},
			{
				value: 'gaoxiongxian',
				label: '高雄县',
			},
			{
				value: 'pingdongxian',
				label: '屏东县',
			},
			{
				value: 'yilanxian',
				label: '宜兰县',
			},
			{
				value: 'huanlianxian',
				label: '花莲县',
			},
			{
				value: 'taidongxian',
				label: '台东县',
			},
			{
				value: 'penghuxian',
				label: '澎湖县',
			},
			{
				value: 'jilongshi',
				label: '基隆市',
			},
			{
				value: 'xinzhushi',
				label: '新竹市',
			},
			{
				value: 'taizhongshi',
				label: '台中市',
			},
			{
				value: 'jiayishi',
				label: '嘉义市',
			},
			{
				value: 'tainanshi',
				label: '台南市',
			},
			{
				value: 'other',
				label: '其他',
			},
		],
	},
	{
		value: 'haiwai',
		label: '海外',
	},
	{
		value: 'other',
		label: '其他',
	},
];
